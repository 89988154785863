import { fetchCategory } from "@/api";
import { ICategory } from "@/lib/interfaces/ICategory";
import { inter, poppins } from "@/styles/font";
import { CategoryItemType } from "@/types/CategoryItemType";
import {
  Button,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GetServerSideProps } from "next";
import Image from "next/image";
import Link from "next/link";
import {
  ErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import { useQuery } from "react-query";
import Loader from "../loader/Loader";

interface initialPageProps {
  category: ICategory[];
}

export const CategoryItem = ({ catId, image, title }: CategoryItemType) => {
  return (
    <Link
      href={{
        pathname: "/catalog/[categoryId]",
        query: { categoryId: catId, category: title.toLowerCase().replaceAll(" ", "-") },
      }}
    >
      <HStack>
        <Image src={image} alt={title} width={22} height={22} />
        <Text
          fontWeight={"normal"}
          color={"martlinegray.900"}
          className={inter.className}
          fontSize={"12px"}
          _hover={{ color: "martlineorange.500" }}
        >
          {title}
        </Text>
      </HStack>
    </Link>
  );
};

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <Flex
      role="alert"
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      className={poppins.className}
    >
      <Text fontWeight={"semibold"}>Something went wrong!</Text>
      <Text fontSize={"sm"}>{error.message}</Text>
      <Button variant={"solid"} onClick={resetErrorBoundary}>
        Try again
      </Button>
    </Flex>
  );
}

const Category = ({ category }: initialPageProps) => {
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: allCategory,
  } = useQuery<ICategory[], Error>("all-categories", fetchCategory, {
    initialData: category,
    useErrorBoundary: true,
  });

  return (
    <Flex
      flexDirection={"column"}
      boxShadow={"0px 2px 4px rgba(0, 0, 0, 0.15)"}
      rounded={"md"}
      py={1}
      pl={3}
      pr={1}
      h={"full"}
      backgroundColor={"white"}
    >
      <Text
        color={"black"}
        className={poppins.className}
        fontSize={"18px"}
        fontWeight={"medium"}
      >
        All Categories
      </Text>

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {isLoading && <Loader />}

        {isSuccess && (
          <VStack h={'full'} justifyContent={'space-evenly'} alignItems={"start"} mt={'1px'}>
            {allCategory.map((item) => (
              <CategoryItem
                key={item._id}
                catId={item._id}
                image={item.icon}
                title={item.title}
              />
            ))}
          </VStack>
        )}
      </ErrorBoundary>
    </Flex>
  );
};

export const getServerSideProps: GetServerSideProps = async (): Promise<{
  props: { category: ICategory[] };
}> => {
  const allCategory = await fetchCategory();
  return { props: { category: allCategory } };
};

export default Category;
